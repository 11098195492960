import { Suspense, lazy } from 'react';
import { AuthGuard } from '../components/authentication/auth-guard';
import ACL from '../components/authentication/ACL';
import LoadingScreen from '../components/loadingScreen';
import { DashboardLayout } from '../components/layout/layout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Dashboard pages
const General = Loadable(lazy(() => import('./dashboard')));
// const Aucu = Loadable(lazy(() => import('./cuau/list')));
const PrestaChoix = Loadable(lazy(() => import('./prestaChoix/index')));
// const PrepaBackOffice = Loadable(lazy(() => import('./prepaBackOffice/index')));
const PrepaBackOffice2 = Loadable(lazy(() => import('./prepaBackOffice/index2')));
const PrepaBackOffice3 = Loadable(lazy(() => import('./prepaBackOffice/index3')));
const ImageEditor = Loadable(lazy(() => import('./imageEditor/index')));
const TtsPostTravaux = Loadable(lazy(() => import('./ttsPostTravaux/ttsPostTravaux')));
const UserList = Loadable(lazy(() => import('./user/list')));
const ReviewAgentMoar = Loadable(lazy(() => import('./reviewAgentMoar/index')));
const ReviewAgentAre = Loadable(lazy(() => import('./reviewAgentAre/index')));
const ReviewMarcheMoar = Loadable(lazy(() => import('./reviewMarcheMoar/index')));
const ReviewMarcheMoar2 = Loadable(lazy(() => import('./reviewMarcheMoar/index.2')));
const ListeSelfcare = Loadable(lazy(() => import('./liste/selfcare.index')));
const ListeD0D5 = Loadable(lazy(() => import('./liste/d0d5.index')));
const ListeBp = Loadable(lazy(() => import('./liste/bp.index')));
const ListePreprog = Loadable(lazy(() => import('./liste/preprog.index')));
const ListeBonuMalus = Loadable(lazy(() => import('./liste/bonusMalus.index')));
const ListeD0SansD2 = Loadable(lazy(() => import('./liste/d0SansD2.index')));
const ListeCuau = Loadable(lazy(() => import('./cuau/index')));
const BpAncien = Loadable(lazy(() => import('./bp/ancien')));
const Import = Loadable(lazy(() => import('./import')));
const Gdpr = Loadable(lazy(() => import('./gdpr.index')));

const Tools = Loadable(lazy(() => import('./tools/tools')));
const Test = Loadable(lazy(() => import('./test/test')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./401')));
const NotFound = Loadable(lazy(() => import('./404')));
const ServerError = Loadable(lazy(() => import('./500')));


const routes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <General />
      },
      {
        path: 'outils',
        element: <Tools />
      },
      {
        path: 'imports',
        element: <Import />
      },
      {
        path: 'gdpr',
        element: <Gdpr />
      },
      {
        path: 'selfcare',
        element: <ListeSelfcare />
      },
      {
        path: 'd0d5',
        element: <ListeD0D5 />
      },
      {
        path: 'preprog',
        element: <ListePreprog />
      },
      {
        path: 'bonusMalus',
        element: <ListeBonuMalus />
      },
      {
        path: 'd0SansD2',
        element: <ListeD0SansD2 />
      },
      {
        path: 'cuau',
        element: <ListeCuau />
      },
      {
        path: 'ttsPostTravaux',
        element: <TtsPostTravaux />
      },
      {
        path: 'prestataire',
        element: <PrestaChoix />
      },
      // {
      //   path: 'prepaBackOfficeOld',
      //   element: <PrepaBackOffice />
      // },
      {
        path: 'prepaBackOffice',
        element: <PrepaBackOffice2 />
      },
      {
        path: 'bp',
        element: <PrepaBackOffice3 />
      },
      {
        path: 'bp/liste',
        element: <ListeBp />
      },
      {
        path: 'bp/ancien',
        element: (
          <BpAncien />
        )
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            element: (
              <ACL allowedRoles={['6GF_ADMIN']} fallback={<AuthorizationRequired />}>
                <UserList />
              </ACL>
            )
          },
          {
            path: 'revueAgentARE',
            element: (
              <ACL allowedRoles={['6GF_DEV']} fallback={<AuthorizationRequired />}>
                <ReviewAgentAre />
              </ACL>
            )
          },
          {
            path: 'revueAgentMOAR',
            element: (
              <ACL allowedRoles={['6GF_RESPONSABLE']} fallback={<AuthorizationRequired />}>
                <ReviewAgentMoar />
              </ACL>
            )
          },
          {
            path: 'marcheMOAR',
            element: (
              <ACL allowedRoles={['6GF_API']} fallback={<AuthorizationRequired />}>
                <ReviewMarcheMoar />
              </ACL>
            )
          },
          {
            path: 'marcheMOAR2',
            element: (
              <ACL allowedRoles={['6GF_API']} fallback={<AuthorizationRequired />}>
                <ReviewMarcheMoar2 />
              </ACL>
            )
          },
        ]
      },
      {
        path: 'test',

        element: (
          <ACL allowedRoles={['6GF_DEV']} fallback={<AuthorizationRequired />}>
            <Test />
          </ACL>
        )
      },

      // {
      //   path: 'cuau',
      //   element: (
      //     <ACL allowedRoles={['6GF_DEV']} fallback={<AuthorizationRequired />}>
      //       <Aucu />
      //     </ACL>
      //   )
      // },
      {
        path: 'image',

        element: (
          <ACL allowedRoles={['6GF_DEV']} fallback={<AuthorizationRequired />}>
            <ImageEditor />
          </ACL>
        )
      },
    ]
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
