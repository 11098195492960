// import FileUploadIcon from '@mui/icons-material/FileUpload';
// import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import TableViewIcon from '@mui/icons-material/TableView';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CalculateIcon from '@mui/icons-material/Calculate';
import UpdateIcon from '@mui/icons-material/Update';
import PersonSearchIcon from '@mui/icons-material/WorkspacesOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import BugReportIcon from '@mui/icons-material/BugReport';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import {
  Box, Grid,
  Button, Divider,
  Drawer, FormControl, InputLabel,
  IconButton, InputAdornment, OutlinedInput, Tooltip, Typography,
  useMediaQuery
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useAuth } from '../../hooks/use-auth';
import ACL from '../authentication/ACL';
import { Logo } from '../logo';
import { Scrollbar } from '../scrollbar';
import { OrganizationPopover } from './sidebar-organisation';
import { DashboardSidebarSection } from './sidebar-section';
import { moment, addBusinessDays } from '../../utils/date';

export const DashboardSidebar = (props) => {

  const [activeUsers, setActiveUsers] = useState([]);

  const { onClose, open } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), { noSsr: true });
  const { user } = useAuth();
  const location = useLocation()

  const organizationsRef = useRef(null);
  const [openOrganizationsPopover, setOpenOrganizationsPopover] = useState(false);

  const getActiveUser = useCallback(async () => {
    try {
      let users = await axios.get('/api/user/active');
      users = users.data.rows


      // set new activeUsers, compair it to previous value, then toast a notification
      setActiveUsers(prevUsers => {
        if (
          // user has already been load once
          prevUsers.length > 0
          // if admin
          && user.userProfile.filter(role => role === '6GF_ADMIN').length
        ) {

          users
            // remove previous users
            .filter(
              user => prevUsers.filter(prevUser => prevUser.nni === user.nni).length === 0
            )
            // notify the connection :)
            // .map(u => console.log(`Nouvelle connexion : ${u.fullName}`))
            .map(u => toast.success(`Nouvelle connexion : ${u.fullName}`))
        }

        return users
      })

    } catch (error) {
      console.error(error);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  // open a EventSource to the server to get import evolution
  useEffect(() => {
    let serverEventSource;
    const reconnectInterval = 1000; // ms

    const connectToServerEvent = () => {
      serverEventSource = new EventSource('/api/user/sync');


      serverEventSource.onmessage = function (event) {
        // debug
        const data = JSON.parse(event.data);
        let users = data

        // console.log('onmessage data :', users);

        // TODO: need cleanning
        // setActiveUsers(oldState => {
        //   const data = JSON.parse(event.data);
        //   return {
        //     appImports: oldState.appImports.map(app => {
        //       return {
        //         ...app,
        //         importProgress: app.label !== undefined ? data?.tasks?.[app?.label]?.progress : null,
        //       };
        //     }),
        //   };
        // });


        setActiveUsers(prevUsers => {
          if (
            // user has already been load once
            prevUsers.length > 0
            // if admin
            && user.userProfile.filter(role => role === '6GF_ADMIN').length
          ) {

            users
              // remove previous users
              .filter(
                user => prevUsers.filter(prevUser => prevUser.nni === user.nni).length === 0
              )
              // notify the connection :)
              // .map(u => console.info(`Nouvelle connexion : ${u.fullName}`))
            // TODO: fix this warning : Cannot update a component (`Ie`) while rendering a different component (`DashboardSidebar`). 
            // To locate the bad setState() call inside `DashboardSidebar`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
            // .map(u => toast.success(`Connexion : ${u.fullName}`))
          }

          return users
        })


      };

      // debug
      // serverEventSource.onopen = function (event) {
      //   console.log('Connection to server opened.');
      // };

      // reconnect
      serverEventSource.onerror = function (event) {
        // console.log('LoggedUsers Error: ', event); //debug
        // Here's where we try to re-establish the connection in case it's closed
        if (serverEventSource.readyState === EventSource.CLOSED) {
          // console.log('LoggedUsers Connection is closed. Attempting to reconnect...'); // debug //debug

          // Wait for the specified interval, then re-establish the connection
          setTimeout(() => {
            // console.log('LoggedUsers Reconnecting...'); // debug //debug
            connectToServerEvent();  // recursively call connectToServerEvent function to set up a new connection
          }, reconnectInterval);
        }
      };
    };

    connectToServerEvent();
    getActiveUser();


    // close this connection when user go to an other page
    return () => {
      if (serverEventSource) {
        // console.log('LoggedUsers Closing the connection...'); //debug
        serverEventSource.close();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActiveUser]);




  // const handleOpenOrganizationsPopover = () => {
  //   setOpenOrganizationsPopover(true);
  // };

  const handleCloseOrganizationsPopover = () => {
    setOpenOrganizationsPopover(false);
  };

  const handlePathChange = () => {
    if (open) {
      onClose?.();
    }
  };

  useEffect(handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]);








  // travaux 
  const [openCalculator, setOpenCalculator] = useState(false);
  const travauxOsrRef = useRef();
  // const travauxDate = useRef();
  const [travauxDate, setTravauxDate] = useState(null);
  const [travauxOsrResult, setTravauxOsrResult] = useState(null);

  const handleClickOpenCalculator = () => {
    setOpenCalculator(true);
  };
  const handleClose = () => {
    setOpenCalculator(false);
  };

  const handleTravauxSearch = async () => {
    try {
      let response = await axios.get('/api/osr/dateD3', { params: { refAffaire: travauxOsrRef.current?.value } });

      const raw = response?.data?.data
      // console.log('updated ', raw, moment(raw?.updatedAt), moment(raw?.updatedAt).fromNow())
      if (!raw.dateAccordClient) {
        setTravauxOsrResult("Pas de D3 dans l'application pour ce dossier, dernière mise à jour " + moment(raw?.updatedAt)?.fromNow())
      } else {

        const dateAccordClient = moment(raw?.dateAccordClient, 'YYYY-MM-DD')
        const dateMAx = moment(dateAccordClient).add(90, 'days')
        // console.log({
        //   raw: response?.data?.data?.dateAccordClient,
        //   dateAccordClient: dateAccordClient.format("YYYY-MM-DD")
        //   , dateMAx: dateMAx.format("YYYY-MM-DD")
        // });

        setTravauxOsrResult(`D3 le ${dateAccordClient.format('DD/MM/YYYY')}, donc travaux à réaliser avant le : ${dateMAx.format('DD/MM/YYYY')}`)
      }

    } catch (error) {
      setTravauxOsrResult("Impossible de calculer la date.")
      console.error(error);
    }

  };



  const sections = [
    {
      title: 'Développeur',
      allowedRoles: ['6GF_DEV'],
      items: [
        // {
        //   title: 'Accueil',
        //   allowedRoles: ['6GF_DEFAULT'],
        //   path: '/',
        //   icon: <HomeOutlinedIcon fontSize="small" />
        // },
        // {
        //   title: 'test',
        //   allowedRoles: ['6GF_DEV'],
        //   path: '/test',
        //   icon: <FingerprintOutlinedIcon fontSize="small" />,
        // },
        {
          title: 'Pré-prog',
          allowedRoles: ['6GF_DEV'],
          path: '/preprog',
          icon: <TipsAndUpdatesIcon fontSize="small" />,
          // sx: { color: '#f5f6f7' }
          sx: { color: 'yellow' },
          colors: { text: 'orange' }
        },
        {
          title: 'Bonus Malus',
          allowedRoles: ['6GF_DEV'],
          path: '/bonusMalus',
          icon: <TipsAndUpdatesIcon fontSize="small" />,
          colors: { text: 'blue' }
          // sx: { color: '#e3e5e9' }
        },
      ],
      sx: { color: 'orange' }
    },

    // {
    //   title: 'Général',
    //   allowedRoles: ['6GF_DEFAULT'],
    //   items: [
    //     {
    //       title: 'Accueil',
    //       allowedRoles: ['6GF_DEFAULT'],
    //       path: '/',
    //       icon: <HomeOutlinedIcon fontSize="small" />
    //     },
    //     {
    //       title: 'test',
    //       allowedRoles: ['6GF_DEV'],
    //       path: '/test',
    //       icon: <FingerprintOutlinedIcon fontSize="small" />
    //     },
    //   ]
    // },

    {
      title: 'ARE',
      allowedRoles: ['6GF_DEFAULT'],
      items: [
        {
          title: 'Prépa Back-Office',
          allowedRoles: ['6GF_DEFAULT'],
          path: '/prepaBackOffice',
          icon: <TableViewIcon fontSize="small" />
        },
        {
          title: 'Branchement Provisoire',
          allowedRoles: ['6GF_DEV'],
          sx: { color: 'yellow' },
          path: '/bp',
          icon: <TableViewIcon fontSize="small" />
        },
        {
          title: 'Branchement Provisoire - Liste',
          allowedRoles: ['6GF_DEV'],
          sx: { color: 'yellow' },
          path: '/bp/liste',
          icon: <TableViewIcon fontSize="small" />
        },
        {
          title: 'Revue agent',
          allowedRoles: ['6GF_RESPONSABLE'],
          path: '/user/revueAgentARE',
          icon: <QueryStatsIcon fontSize="small" />
        },
        {
          title: 'CUAU',
          allowedRoles: ['6GF_DEFAULT'],
          path: '/cuau',
          icon: <RouteOutlinedIcon fontSize="small" />
        },
        {
          title: 'D0SansD2',
          allowedRoles: ['6GF_DEFAULT'],
          path: '/d0SansD2',
          icon: <TipsAndUpdatesIcon fontSize="small" />,
          // sx: { color: '#f5f6f7' }
          sx: { color: 'yellow' },
          colors: { text: 'orange' }
        },
      ]
    },
    {
      title: 'MOAR',
      allowedRoles: ['6GF_DEFAULT'],
      items: [
        {
          title: 'Revue agent',
          allowedRoles: ['6GF_RESPONSABLE'],
          path: '/user/revueAgentMOAR',
          icon: <QueryStatsIcon fontSize="small" />
        },
        {
          title: 'Conso marché',
          allowedRoles: ['6GF_DEFAULT'],
          // path: '/user/marcheMOARNew',
          path: '/user/marcheMOAR',
          icon: <QueryStatsIcon fontSize="small" />
        },
        {
          title: 'Bonus Malus',
          allowedRoles: ['6GF_DEV'],
          path: '/bonusMalus',
          icon: <TipsAndUpdatesIcon fontSize="small" />,
          // colors: { text: 'blue' }
          // sx: { color: '#e3e5e9' }
        },
        {
          title: 'Choisir un prestataire',
          allowedRoles: ['6GF_DEFAULT'],
          path: '/prestataire',
          icon: <MapsUgcOutlinedIcon fontSize="small" />
        },
        {
          title: 'Selfcare',
          allowedRoles: ['6GF_DEFAULT'],
          path: '/selfcare',
          icon: <RouteOutlinedIcon fontSize="small" />
        },
        {
          title: 'BP ancien',
          allowedRoles: ['6GF_DEV'],
          path: '/bp/ancien',
          icon: <RouteOutlinedIcon fontSize="small" />
        },
        {
          title: 'Calculette dates',
          allowedRoles: ['6GF_DEFAULT'],
          onClick: handleClickOpenCalculator,
          icon: <CalculateIcon fontSize="small" />
        },
        {
          title: 'D0-D5 > 150J',
          allowedRoles: ['6GF_DEFAULT'],
          path: '/d0d5',
          icon: <QueryStatsIcon fontSize="small" />
        },
        // {
        //   title: 'Pré-prog',
        //   allowedRoles: ['6GF_DEV'],
        //   path: '/preprog',
          // icon: <BugReportIcon fontSize="small" />
        // },
        // {
        //   title: 'Bonus Malus',
        //   allowedRoles: ['6GF_DEV'],
        //   path: '/bonusMalus',
          // icon: <BugReportIcon fontSize="small" />
        // },
      ]
    },
    // {
    //   title: 'APF',
    //   allowedRoles: ['6GF_APF'],
    //   items: [
    //     {
    //       title: 'Appel post travaux',
    //       allowedRoles: ['6GF_APF'],
    //       path: '/ttsPostTravaux',
    //       icon: <RouteOutlinedIcon fontSize="small" />
    //     },
    //   ]
    // },
    {
      title: 'Gestion',
      allowedRoles: ['6GF_DEFAULT'],
      items: [
        {
          title: 'Utilisateurs',
          allowedRoles: ['6GF_ADMIN'],
          path: '/user',
          icon: <PersonSearchIcon fontSize="small" />
        },
        {
          title: 'Etat des robots',
          allowedRoles: ['6GF_DEFAULT'],
          path: '/imports',
          icon: <VisibilityIcon fontSize="small" />
        },
      ]
    },
  ];

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {/* logo */}
          <Box sx={{
            p: 3,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
            <RouterLink to={'/'}>
              <Logo
                sx={{
                  height: 42,
                  width: 42
                }}
              />
            </RouterLink>
            <Typography variant="h5" ml={1} >
              Portail-ARMGP
            </Typography>
          </Box>

          {/* user & area info */}
          {/* <Box sx={{ px: 2 }}>
              <Box
                onClick={handleOpenOrganizationsPopover}
                ref={organizationsRef}
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 3,
                  py: '11px',
                  borderRadius: 1
                }}
              >
                <div>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                  >
                    {user.cn}
                  </Typography>
                  <Typography
                    color="neutral.400"
                    variant="body2"
                  >
                    {'Direction Centre Val de Loire'}
                  </Typography>
                </div>
                <UnfoldMoreIcon
                  sx={{
                    color: 'neutral.500',
                    width: 20,
                    height: 20
                  }}
                />
              </Box>
            </Box> */}

          {/* menu */}
          <Divider
            sx={{
              borderColor: '#2D3748',  // dark divider
              my: 3
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            {sections.map((section) => (
              <DashboardSidebarSection
                key={section.title}
                path={location.pathname}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2
                  },
                  ...section?.sx
                }}
                {...section} />
            ))}
          </Box>

          <Divider
            sx={{
              borderColor: '#2D3748',  // dark divider
            }}
          />

          {/* utilisateurs actifs */}
          <ACL
            allowedRoles={['6GF_DEV']}
            fallback={<Typography
              color="neutral.500"
              variant="body2"
            >
              {activeUsers?.length}  utilisateurs actifs
            </Typography>}
          >
            <Box sx={{ p: 2 }}>
              <Tooltip
                title={
                  (Array.isArray(activeUsers)
                    && activeUsers.length > 0) ?
                    activeUsers.map(u => (u.fullName)).join(', ') :
                    ''
                }
              >
                <Box>
                  <Typography
                    color="neutral.500"
                    variant="body2"
                  >
                    {activeUsers?.length}  utilisateurs actifs
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          </ACL>

          <Divider
            sx={{
              borderColor: '#2D3748',  // dark divider
            }}
          />

          {/* où sommes nous ? */}
          <Box sx={{ p: 2 }}>
            <Typography
              color="neutral.100"
              variant="subtitle2"
            >
              {'Où sommes nous ?'}
            </Typography>
            <Typography
              color="neutral.500"
              variant="body2"
            >
              {"Cette application est en cours de développement et remplacera Pilot-ARMGP en reprenant progressivement ses fonctionnalités."}
            </Typography>
          </Box>

          <Divider
            sx={{
              borderColor: '#2D3748',  // dark divider
            }}
          />

          {/* new dialog */}
          <Dialog
            open={openCalculator}
            onClose={handleClose}
            maxWidth='lg'
            PaperProps={{
              sx: {
                width: "100%",
                // maxWidth: "720px!important",
                'align-items': "center"
              },
            }}
          >
            <DialogContent>
              <Box sx={{ width: '100%', maxWidth: 1200, bgcolor: 'background.paper' }}>
                <Grid container spacing={2}>
                  {/* first part */}
                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h5" component="div">
                      Calcul de la date limite de réalisation de travaux client à partir de la D3
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      <Grid container alignItems="center">
                        <Grid item>
                          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor={'osr'}>
                              Ref OSR
                            </InputLabel>
                            <OutlinedInput
                              inputRef={travauxOsrRef}
                              type="text"
                              id="osr"
                              label='refOSR'
                              fullWidth
                              onChange={() => {
                                if (travauxOsrRef?.current?.value.length === 8) { handleTravauxSearch() }
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Rechercher"
                                    onClick={handleTravauxSearch}
                                    edge="end"
                                  >
                                    <UpdateIcon color="inherit" />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>
                          OU
                        </Grid>
                        <Grid item>
                          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <DatePicker
                              id='date'
                              value={travauxDate}
                              renderInput={(params) => <TextField {...params} fullWidth />}
                              onChange={(newValue) => {
                                const dateAccordClient = moment(newValue, 'DD/MM/YYYY')
                                const dateMAx = moment(dateAccordClient).add(90, 'days')
                                setTravauxOsrResult(`D3 le ${dateAccordClient.format('DD/MM/YYYY')}, donc travaux à réaliser avant le : ${dateMAx.format('DD/MM/YYYY')}`)
                                setTravauxDate(newValue)
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Typography>
                    Résultat: <br /> {travauxOsrResult}
                  </Grid>

                  {/* second part */}
                  <Grid item xs={6}>
                    <Typography gutterBottom variant="h5" component="div">
                      Calcul JL
                    </Typography>
                    <Typography gutterBottom variant="body1">
                      <br />
                      Dans 15j ouvrés nous serons le: {addBusinessDays(moment(), 15).format('DD/MM/YYYY')} (branchement)  <br />
                      Dans 25j ouvrés nous serons le: {addBusinessDays(moment(), 25).format('DD/MM/YYYY')} (branchement + terrassement)  <br />
                      Dans 45j ouvrés nous serons le: {addBusinessDays(moment(), 45).format('DD/MM/YYYY')} (délai maxi travaux presta)
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Dialog>

          {/* old */}

          {/* assistance */}
          <Box sx={{ p: 2 }}>
            <Typography
              color="neutral.100"
              variant="subtitle2"
            >
            </Typography>
            <Button
              color="secondary"
              component="a"
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
              href="https://teams.microsoft.com/l/chat/0/0?users=mathieu-m.fernandes%40enedis.fr"
            >
              {'Un bug ? Contactez moi'}
            </Button>
          </Box>
        </Box>
      </Scrollbar >

      {/* direction list */}
      < OrganizationPopover
        anchorEl={organizationsRef.current}
        onClose={handleCloseOrganizationsPopover}
        open={openOrganizationsPopover}
      />
    </>
  );


  // wide or narrow window
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
