import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useAuth } from '../../hooks/use-auth';
import { stringAvatar } from '../../utils/avatar';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { logout, login } = useAuth();
  // To get the user from the authContext, you can use
  const { user } = useAuth();

  // console.log("user",
  //   user,
  //   // user.userProfile
  //   // .filter(role => role.startsWith('6GF'))
  //   // .map(role => (role.replaceAll('6GF_', '')))
  // )

  const handleLogout = async () => {
    try {
      onClose?.();
      await logout();
    } catch (error) {
      console.error(error);
      toast.error('Impossible de se déconnecter');
    }
  };

  const handleLogin = async () => {
    try {
      onClose?.();
      await login();
    } catch (error) {
      console.error(error);
      toast.error('Impossible de se reconnecter');
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}>
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex'
        }}
      >

        <Avatar
          {...stringAvatar(user.fullName)}
        />
        <Box
          sx={{
            ml: 1
          }}
        >
          <Typography variant="body1">
            {user.fullName}
          </Typography>
          {/* <Typography
            color="textSecondary"
            variant="body2"
          >
            Roles :
         
          </Typography> */}

        </Box>
      </Box>
      {/* <ACL allowedRoles={['6GF_ADMIN']}
              fallback={<ACL allowedRoles={['6GF_RESPONSABLE']}
                fallback={<ACL allowedRoles={['6GF_DEFAULT']}
                  fallback={<>Visiteur</>}>
                  <>Responsable</>
                </ACL>}>
                <>Responsable</>
              </ACL>
              }>
              <>Administrateur</>
            </ACL> */}
      <Divider />
      <Box
        sx={{
          ml: 1
        }}
      >
        {user.userProfileSimple.map(role => (
          <Typography
            key={role}
            color="textSecondary"
            variant="overline"
            sx={{ ml: '25px' }}
          >
            {role}<br />
          </Typography>
        ))}
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <MenuItem onClick={handleLogin}>
          <ListItemIcon>
            <LoginIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                Se reconnecter
              </Typography>
            )}
          />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                Se déconnecter
              </Typography>
            )}
          />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
